import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  title: "",
  number: null,
  description: "",
  price: null,
  video: "",

  // локальная загрузка изображений (DropZone)
  image_local: [],

  // ссылка на cloudinary
  image: "",

  kitchenware: "",
  capacity: null,
  gallery: [],
  gallery_local: [],
  area: null,
  blocked_dates: []
}
const cottage = createSlice({
  name: 'cottage',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_cottage(state) {
      Object.assign(state, initialState);
    },
    set_cottage(state, action) {
      state = action.payload
      return state
    },
    set_title(state, action) {
      state.title = action.payload
    },
    set_video(state, action) {
      state.video = action.payload
    },
    set_number(state, action) {
      state.number = action.payload
    },
    set_description(state, action) {
      state.description = action.payload
    },
    set_price(state, action) {
      state.price = action.payload
    },
    set_image_local(state, action) {
      state.image_local = action.payload
    },
    set_image(state, action) {
      state.image = action.payload
    },
    set_kitchenware(state, action) {
      state.kitchenware = action.payload
    },
    set_capacity(state, action) {
      state.capacity = action.payload
    },
    set_gallery_local(state, action) {
      state.gallery_local = action.payload
    },
    set_gallery(state, action) {
      state.gallery = action.payload
    },
    set_area(state, action) {
      state.area = action.payload
    },
    set_blocked_dates(state, action) {
      state.blocked_dates = action.payload
    },
    delete_gallery_image(state, action) {
      state.gallery = state.gallery?.filter(item => item?.uuid !== action?.payload)
      return state
    },
  },
});

export const {
  reset_cottage,
  set_cottage,
  set_title,
  set_number,
  set_description,
  set_price,
  set_image_local,
  set_kitchenware,
  set_capacity,
  set_image,
  set_gallery,
  set_gallery_local,
  set_video,
  set_area,
  set_blocked_dates,
  delete_gallery_image,
} = cottage.actions;

export default cottage.reducer;
