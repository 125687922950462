import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  uuid: "",
  title: "",
  number: null,
  description: "",

  // ссылка на cloudinary
  image: "",

  // локальная загрузка изображений (DropZone)
  image_local: [],

  gallery: [],
  gallery_local: [],
  price: null,
  capacity: null,
  blocked_dates: [],
  conditions: ""
}
const dome = createSlice({
  name: 'dome',
  initialState: initialState,
  reducers: {
    /** Сброс state */
    reset_dome(state) {
      Object.assign(state, initialState);
    },
    set_dome(state, action) {
      state = action.payload
      return state
    },
    set_title(state, action) {
      state.title = action.payload
    },
    set_number(state, action) {
      state.number = action.payload
    },
    set_description(state, action) {
      state.description = action.payload
    },
    set_image(state, action) {
      state.image = action.payload
    },
    set_image_local(state, action) {
      state.image_local = action.payload
    },
    set_gallery(state, action) {
      state.gallery = action.payload
    },
    set_gallery_local(state, action) {
      state.gallery_local = action.payload
    },
    set_price(state, action) {
      state.price = action.payload
    },
    set_capacity(state, action) {
      state.capacity = action.payload
    },
    delete_gallery_image(state, action) {
      state.gallery = state.gallery?.filter(item => item?.uuid !== action?.payload)
      return state
    },
    set_blocked_dates(state, action) {
      state.blocked_dates = action.payload
    },
    set_conditions(state, action) {
      state.conditions = action.payload
    },
  },
});

export const {
  reset_dome,
  set_dome,
  set_title,
  set_number,
  set_description,
  set_image,
  set_image_local,
  set_gallery,
  set_gallery_local,
  set_price,
  set_capacity,
  delete_gallery_image,
  set_conditions,
  set_blocked_dates
} = dome.actions;

export default dome.reducer;
